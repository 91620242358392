import React from 'react'
import ReactMarkdown from "react-markdown"
import { graphql } from 'gatsby'
import TopMenu from '../components/topMenu'
import SEO from "../components/seo"
import Footer from "../components/footer"
import Title from '../components/titles/title'

const PrivacyTemplate = ({ data, pageContext }) => {
  const { frontmatter: content } = data.content
  const slug = content.slug;
  const settings = data.settings.childMdx.frontmatter;
  
  return (
    <section className={"page lang-" + pageContext.language}>
      <SEO
        title={content.title}
        description={content.description}
      />
      <div className="page__in container-1200">
        <TopMenu smallLogo={true} lang={content.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation} settings={settings}></TopMenu>

          <div className="mb3">
            <div className="posr mb2">
              <Title label1={content.title}></Title>
              {/* <h1 className="color-gold">{content.title}</h1> */}
            </div>
            <ReactMarkdown source={content.content} linkTarget="_blank" className="text"/>
          </div>
      </div>
      <Footer lang={content.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default PrivacyTemplate

export const indexQuery = graphql`
  query PrivacyById($id: String!) {
    content: mdx(
      id: { eq: $id }
    ) {
      frontmatter {
        slug
        language
        title
        description
        content
      }
    }
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          detectLanguage
          address
          address2
          phone
          instagram
          facebook
          pinterest
          googlemaplink
          email
        }
      }
    }
    
  }
`